<script setup lang="ts">
import TagQueryTextField from "@/components/extended/TagQueryTextField.vue";
import {ref} from "vue";

const value = ref("");
const count = ref(0);
const valid = ref(true);
</script>

<template>
  <div class="tag-query">
    <div class="sandbox">
      <TagQueryTextField
          v-model="value"
          @count="count = $event"
          type="sim"
          @update:is-valid="valid = $event"
          allow-test-variables
          required/>
      <div class="count">
        Count: {{ count }}
      </div>
      <div class="valid">
        Valid: {{ valid }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.sandbox {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
}

.count {
  text-align: right;
  padding-right: 4px;
  font-size: 14px;
  color: #666666;
}
</style>
