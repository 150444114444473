import { render, staticRenderFns } from "./Sandbox.vue?vue&type=template&id=093296a3&scoped=true"
import script from "./Sandbox.vue?vue&type=script&setup=true&lang=ts"
export * from "./Sandbox.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Sandbox.vue?vue&type=style&index=0&id=093296a3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093296a3",
  null
  
)

export default component.exports